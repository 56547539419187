<template>
</template>

<script>
    export default {
        name: "BoardFilterNoneLayout",
        props: ['filterData', 'setData'],
        data() {
            return {

            }
        },
        computed: {

        },
        mounted() {

        },
        methods: {

        },
        watch: {
        },
    }
</script>

<style scoped>

</style>